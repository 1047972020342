import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@mui/material';
import Layout from '../../components/Layout';
import ContentContainer from '../../styles/content-container';
import FeaturedCard from '../../components/FeaturedCard';
import { SEO } from '../../components/Seo';

import theme from '../../styles/theme';

export default function Blog({ data }) {
  const { edges: latestArticles } = data.allMarkdownRemark;

  return (
    <Layout>
      <ContentContainer
        sx={{
          marginBottom: '6%',
          // [theme.breakpoints.up('lg')]: { display: 'block' },
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexFlow: 'wrap',
          },
          [theme.breakpoints.down('sm')]: { display: 'block' },
        }}
      >
        <Typography variant="h1" sx={{ marginBottom: '6%', width: '100%' }}>
          Latest News
        </Typography>
        {latestArticles &&
          latestArticles.length > 1 &&
          latestArticles.map((article) => (
            <FeaturedCard
              href={`/blog/${article.node.frontmatter.path}`}
              title={article.node.frontmatter.title}
              summary={article.node.frontmatter.summary_text}
              src={article.node.frontmatter.summary_image}
              alt="Summary"
              sx={{
                // [theme.breakpoints.up('lg')]: { width: '100%' },
                [theme.breakpoints.up('sm')]: { width: '48%' },
                [theme.breakpoints.down('sm')]: { width: '100%' },
              }}
            />
          ))}
      </ContentContainer>
    </Layout>
  );
}

export const Head = () => <SEO />;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { collection: { eq: "blog" } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            summary_text
            summary_image
          }
        }
      }
    }
  }
`;
